<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <div class="flex justify-center">
          <img class="h-6" src="/assets/images/wollo-brand-green.svg" />
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <p>
        Pending
      </p>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonContent,
  IonHeader,
  IonPage,
  // IonTitle,
  IonToolbar
} from '@ionic/vue';

export default defineComponent({
  components: { 
    IonContent, 
    IonHeader, 
    IonPage, 
    // IonTitle, 
    IonToolbar 
  }
});
</script>
